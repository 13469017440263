<template>
  <div id="app">
    <v-app id="main">
      <NavigationDrawer v-if="route==='calendar'" />
      <v-content>
        <v-container :class="{ 'no-container-padding': route==='calendar' }" fluid fill-height>
          <router-view />
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import { CALENDAR_WEEKDAYS } from "@/settings";
import NavigationDrawer from "@/components/drawer/NavigationDrawer";

export default {
  components: {
    NavigationDrawer
  },

  computed: {
    route() {
      return this.$route.name;
    }
  },

  beforeCreate() {
    if (!this.$store.state.users.userData && localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user"));
      this.$store.commit("setUserData", userData);
    }
    /* Wokaround for bug https://github.com/vuetifyjs/vuetify/issues/9114
   Remove when resolved */
    this.$store.commit("setWeekdays", CALENDAR_WEEKDAYS);
  }
};
</script>

<style scoped>
.no-container-padding {
  padding: 0px;
}
</style>
