import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import events from "./modules/events";
import calendar from "./modules/calendar";
import categories from "./modules/categories";
import navigation from "./modules/navigation";
import teams from "./modules/teams";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    events,
    calendar,
    categories,
    navigation,
    teams
  }
});
