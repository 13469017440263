import { CATEGORY_COLORS } from "@/settings";

const categoriesData = {
  computed: {
    categoriesData() {
      return this.$store.state.categories.categoriesData;
    }
  }
};

const getCategoryNameFromId = {
  methods: {
    getCategoryNameFromId(categoryId) {
      const categoryObject = this.categoriesData.find(
        category => category.id === categoryId
      );
      return categoryObject.name;
    }
  }
};

const getCategoryColors = {
  data: () => ({
    colors: CATEGORY_COLORS
  }),
  methods: {
    getCategoryColors(categoryName) {
      const allCategoryNames = this.categoriesData.map(
        category => category.name
      );
      return this.colors[allCategoryNames.indexOf(categoryName)];
    }
  }
};

export { getCategoryColors, categoriesData, getCategoryNameFromId };
