<template>
  <div>
    <v-switch
      ref="switch"
      v-model="selectAll"
      :label="$t('drawer.selectAll')"
      color="primary"
      @change="selectAllChange(categoryChange)"
    ></v-switch>
    <v-checkbox
      v-for="category in allData"
      v-model="selectedData"
      :key="category.id"
      :label="category.name"
      :value="category"
      :color="getCategoryColors(category.name)"
      @change="categoryChange"
    ></v-checkbox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { categoriesData, getCategoryColors } from "@/mixins/categoriesMixin";
import { pickerMixin } from "@/mixins/pickerMixin";

export default {
  mixins: [categoriesData, getCategoryColors, pickerMixin],

  computed: {
    ...mapState({
      eventsData: state => state.events.eventsData,
      calendarStart: state => state.calendar.start
    }),

    allData() {
      return this.categoriesData;
    },

    loadedEventCategories() {
      return Object.keys(this.eventsData);
    },

    selectedCategoriesNames() {
      return this.selectedData.map(category => category.name);
    },

    newEventsForCategory() {
      return this.loadedEventCategories.length === 0
        ? this.selectedData
        : this.selectedData.filter(
            category => !this.loadedEventCategories.includes(category.name)
          );
    },

    categoryToRemove() {
      return this.loadedEventCategories.filter(
        categoryName => !this.selectedCategoriesNames.includes(categoryName)
      );
    }
  },

  watch: {
    calendarStart(newValue, oldValue) {
      if (
        newValue.year !== oldValue.year ||
        newValue.month !== oldValue.month
      ) {
        this.getEventsForCategories(this.selectedData);
      }
    }
  },

  created() {
    this.handleInitialLoad(
      "getEventCategories",
      "selectedCategories",
      this.getEventsForCategories,
      true
    );
  },

  methods: {
    categoryChange() {
      const newEventsForCategory = this.newEventsForCategory;
      const categoryToRemove = this.categoryToRemove;

      localStorage.setItem(
        "selectedCategories",
        JSON.stringify(this.selectedData)
      );

      this.checkIfAllDataIsSelected();

      if (categoryToRemove.length > 0) {
        this.removeEventsForUnclickedCategory(categoryToRemove);
      }

      if (newEventsForCategory.length > 0) {
        this.getEventsForCategories(newEventsForCategory);
      }
    },

    getEventsForCategories(categories) {
      this.$store.commit("setEventsLoaded", false);

      const promises = categories.map(category => {
        return this.$store.dispatch("getEventsForCategory", {
          category: category,
          date: {
            year: this.calendarStart.year,
            month: this.calendarStart.month
          }
        });
      });

      Promise.all(promises).then(() =>
        this.$store.commit("setEventsLoaded", true)
      );
    },

    removeEventsForUnclickedCategory(categoryToRemove) {
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      this.$store.commit("setEventsLoaded", false);

      categoryToRemove.forEach(categoryName =>
        this.$store.commit("removeEventData", categoryName)
      );

      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      setTimeout(() => this.$store.commit("setEventsLoaded", true), 5);
    }
  }
};
</script>
