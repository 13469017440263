import axios from "../../plugins/axios";

export default {
  state: {
    teamsData: []
  },

  mutations: {
    setTeamsData(state, data) {
      state.teamsData = data;
    }
  },

  actions: {
    getTeamsData({ commit }) {
      return axios.get("/api/teams/").then(response => {
        commit("setTeamsData", response.data);
      });
    }
  }
};
