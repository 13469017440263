import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const lazyLoad = view => {
  return() => import(`@/views/${view}.vue`)
}

export const router = new Router({
  mode: "history",
  base: "calendar",
  routes: [
    {
      path: "/",
      name: "calendar",
      component: lazyLoad('Calendar')
    },
    {
      path: "/login",
      name: "login",
      component: lazyLoad('Login')
    }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});
