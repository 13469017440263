<template>
  <v-switch
    v-model="showOnlyWorkingWeek"
    :label="$t('drawer.onlyWorkingWeek')"
    color="primary"
    @change="showOnlyWorkingWeekChange"
  ></v-switch>
</template>

<script>
import { CALENDAR_WEEKDAYS } from "@/settings";

export default {
  data: () => ({
    showOnlyWorkingWeek: false
  }),

  created() {
    const showOnlyWorkingWeek = JSON.parse(
      localStorage.getItem("showOnlyWorkingWeek")
    );

    if (showOnlyWorkingWeek) {
      this.showOnlyWorkingWeek = showOnlyWorkingWeek;
      this.changeWeekdays();
    }
  },

  methods: {
    showOnlyWorkingWeekChange() {
      this.changeWeekdays();
    },

    changeWeekdays() {
      if (this.showOnlyWorkingWeek) {
        this.$store.commit("setWeekdays", CALENDAR_WEEKDAYS.slice(0, 5));
      } else {
        this.$store.commit("setWeekdays", CALENDAR_WEEKDAYS);
      }

      localStorage.setItem(
        "showOnlyWorkingWeek",
        JSON.stringify(this.showOnlyWorkingWeek)
      );
    }
  }
};
</script>
