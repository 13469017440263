export default {
  state: {
    selectedTeams: [],
    selectedUsers: [],
    start: {},
    end: {},
    weekdays: []
  },

  mutations: {
    setSelectedTeams(state, data) {
      state.selectedTeams = data;
    },

    setSelectedUsers(state, data) {
      state.selectedUsers = data;
    },

    setEnd(state, data) {
      state.end = data;
    },

    setStart(state, data) {
      state.start = data;
    },

    setWeekdays(state, data) {
      state.weekdays = data;
    }
  }
};
