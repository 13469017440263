import axios from "../../plugins/axios";
import { router } from "../../router";

export default {
  state: {
    userData: null,
    allUserData: null,
    logInError: null
  },

  mutations: {
    setUserData(state, data) {
      state.userData = data;
    },

    setAllUserData(state, data) {
      state.allUserData = data;
    },

    setLogInError(state, error) {
      state.logInError = error;
    }
  },

  actions: {
    doLogin({ commit }, { username, password }) {
      axios
        .post("/api/login/", {
          username,
          password
        })
        .then(response => {
          localStorage.setItem("user", JSON.stringify(response.data));

          commit("setUserData", response.data);
          commit("setLogInError", null);
          commit("setShowDrawer", true);

          router.push({ name: "calendar" });
        })
        .catch(error => {
          commit("setLogInError", error.response.data);
          commit("setUserData", null);
        });
    },

    doLogout({ commit }) {
      localStorage.removeItem("user");

      commit("setUserData", null);
      commit("setShowDrawer", false);

      router.push({ name: "login" });
    },

    getAllUserData({ commit }) {
      return axios.get("/api/users/").then(response => {
        commit("setAllUserData", response.data);
      });
    }
  }
};
