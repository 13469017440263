import axios from "../../plugins/axios";
import Vue from "vue";

export default {
  state: {
    eventsData: {},
    saveError: null,
    eventsLoaded: false
  },

  getters: {
    eventsArray(state) {
      const eventsByCategory = Object.values(state.eventsData);
      return state.eventsLoaded ? [].concat(...eventsByCategory) : [];
    }
  },

  mutations: {
    setEventData(state, { categoryName, data }) {
      Vue.set(state.eventsData, categoryName, data);
    },

    removeEventData(state, categoryName) {
      Vue.delete(state.eventsData, categoryName);
    },

    setEventsLoaded(state, data) {
      state.eventsLoaded = data;
    },

    removeDeletedEvent(state, { categoryName, data }) {
      const eventToDeleteIndex = state.eventsData[categoryName].findIndex(
        event => event.id === data.id
      );

      state.eventsData[categoryName].splice(eventToDeleteIndex, 1);
    },

    setUpdatedEvent(state, { newCategoryName, oldCategoryName, data }) {
      const getEventIndex = categoryName => {
        return state.eventsData[categoryName].findIndex(
          event => event.id === data.id
        );
      };
      const deleteOldEvent = oldCategoryName => {
        const eventToDeleteIndex = getEventIndex(oldCategoryName);
        state.eventsData[oldCategoryName].splice(eventToDeleteIndex, 1);
      };

      if (state.eventsData[newCategoryName]) {
        const eventToUpdateIndex = getEventIndex(newCategoryName);

        if (eventToUpdateIndex > 0) {
          Vue.set(state.eventsData[newCategoryName], eventToUpdateIndex, data);
        } else {
          deleteOldEvent(oldCategoryName);
          state.eventsData[newCategoryName].push(data);
        }
      } else {
        deleteOldEvent(oldCategoryName);
      }
    },

    setSavedEvent(state, { category, data }) {
      state.eventsData[category].push(data);
    },

    setSaveError(state, data) {
      state.saveError = data;
    }
  },

  actions: {
    getEventsForCategory({ commit }, { category, date }) {
      return axios
        .get(`${category.events}${date.year}/${date.month}/`)
        .then(response => {
          commit("setEventData", {
            categoryName: category.name,
            data: response.data
          });
        });
    },

    deleteEvent({ commit }, { categoryName, event }) {
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      commit("setEventsLoaded", false);

      axios.delete(`${event.url}`).then(() => {
        commit("removeDeletedEvent", {
          categoryName,
          data: event
        });
        // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
        commit("setEventsLoaded", true);
      });
    },

    updateEvent({ commit }, { newCategoryName, oldCategoryName, event }) {
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      commit("setEventsLoaded", false);

      return axios
        .patch(`${event.url}`, {
          category: event.category,
          start_date: event.start_date,
          end_date: event.end_date,
          description: event.description
        })
        .then(response => {
          commit("setUpdatedEvent", {
            newCategoryName,
            oldCategoryName,
            data: response.data
          });
          commit("setSaveError", null);
          // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
          commit("setEventsLoaded", true);
        })
        .catch(error => {
          commit("setSaveError", error.response.data);
        });
    },

    saveEvent({ commit }, { category, event }) {
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      commit("setEventsLoaded", false);

      return axios
        .post("/api/events/", {
          ...event
        })
        .then(response => {
          commit("setSavedEvent", {
            category,
            data: response.data
          });
          commit("setSaveError", null);
          // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
          commit("setEventsLoaded", true);
        })
        .catch(error => {
          commit("setSaveError", error.response.data);
        });
    }
  }
};
