export default {
  state: {
    showDrawer: true
  },

  mutations: {
    setShowDrawer(state, data) {
      state.showDrawer = data;
    }
  }
};
