const pickerMixin = {
  data: () => ({
    selectAll: false,
    selectedData: []
  }),

  methods: {
    selectAllChange(dataChangeMethod) {
      if (this.selectAll) {
        this.selectedData = this.allData;
        dataChangeMethod();
      } else {
        this.selectedData = [];
        dataChangeMethod();
      }
    },

    handleInitialLoad(
      actionName,
      storageItemName,
      onLoadMethod,
      onLoadMethodPassData = false
    ) {
      this.$store.dispatch(actionName).then(() => {
        const savedData = localStorage.getItem(storageItemName);
        if (savedData) {
          this.selectedData = JSON.parse(savedData);
          onLoadMethodPassData
            ? onLoadMethod(this.selectedData)
            : onLoadMethod();

          this.checkIfAllDataIsSelected();
        } else {
          this.$refs.switch.$el.getElementsByTagName("input")[0].click();
        }
      });
    },

    checkIfAllDataIsSelected() {
      if (this.selectedData.length === this.allData.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  }
};

export { pickerMixin };
