import Vue from "vue";
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import App from "./App.vue";
import { router } from "./router";
import store from "./store/index";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a9b132ade2cc427baa54625c7be00fb7@sentry9.sds.rocks/68",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  });
}

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
