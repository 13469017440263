import colors from "vuetify/lib/util/colors";

const CALENDAR_WEEKDAYS = [1, 2, 3, 4, 5, 6, 0];

const CATEGORY_COLORS = [
  colors.blueGrey.lighten1,
  colors.pink.lighten1,
  colors.purple.lighten1,
  colors.blue.lighten1,
  colors.cyan.lighten1,
  colors.teal.lighten1,
  colors.lime.lighten1,
  colors.orange.lighten1,
  colors.red.lighten1,
];

const DATTEPICKER_FIRST_DAY = 1;

const LOCALE = "HR";

export {
  CALENDAR_WEEKDAYS,
  CATEGORY_COLORS,
  DATTEPICKER_FIRST_DAY,
  LOCALE
};
