<template>
  <div>
    <v-switch
      ref="switch"
      v-model="selectAll"
      :label="$t('drawer.selectAll')"
      color="primary"
      @change="selectAllChange(userChange)"
    ></v-switch>
    <v-checkbox
      v-model="selectedData"
      v-for="user in allUserData"
      :key="user.id"
      :label="`${user.first_name} ${user.last_name}`"
      :value="user.id"
      @change="userChange"
    ></v-checkbox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { pickerMixin } from "@/mixins/pickerMixin";

export default {
  mixins: [pickerMixin],

  computed: {
    ...mapState({
      allUserData: state => state.users.allUserData,
      selectedUsers: state => state.calendar.selectedUsers,
      teamsData: state => state.teams.teamsData
    }),

    allData() {
      return this.allUserData.map(user => user.id);
    }
  },

  created() {
    this.handleInitialLoad(
      "getAllUserData",
      "selectedUsers",
      this.commitSelectedUsers
    );
  },

  watch: {
    selectedUsers() {
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      this.$store.commit("setEventsLoaded", false);
      this.selectedData = this.selectedUsers;
      this.checkIfAllDataIsSelected();
      localStorage.setItem("selectedUsers", JSON.stringify(this.selectedData));
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      setTimeout(() => this.$store.commit("setEventsLoaded", true), 5);
    }
  },

  methods: {
    userChange() {
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      this.$store.commit("setEventsLoaded", false);
      this.commitSelectedUsers();
      localStorage.setItem("selectedUsers", JSON.stringify(this.selectedData));
      // https://gl.sds.rocks/razvojni-ciljevi/calendar/issues/5
      setTimeout(() => this.$store.commit("setEventsLoaded", true), 5);

      this.selectTeamsForSelectedUsers();
    },

    commitSelectedUsers() {
      this.$store.commit("setSelectedUsers", this.selectedData);
    },

    selectTeamsForSelectedUsers() {
      const teamsDataIds = this.teamsData.map(team => team.id);
      const selectedUsersIds = this.selectedData;

      const teamIdsForSelectedUsers = this.allUserData
        .filter(user => selectedUsersIds.includes(user.id))
        .filter(user => teamsDataIds.includes(user.team))
        .map(user => user.team);

      const uniqueTeamIdsForSelectedUsers = [
        ...new Set(teamIdsForSelectedUsers)
      ];

      this.$store.commit("setSelectedTeams", uniqueTeamIdsForSelectedUsers);
    }
  }
};
</script>
