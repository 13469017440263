import axios from "../../plugins/axios";

export default {
  state: {
    categoriesData: [],
    categoriesForUser: []
  },

  mutations: {
    setEventCategories(state, data) {
      state.categoriesData = data;
    },

    setCategoriesForUser(state, data) {
      state.categoriesForUser = data;
    }
  },

  actions: {
    getEventCategories({ commit }) {
      return axios.get("/api/categories/").then(response => {
        commit("setEventCategories", response.data);
      });
    },

    getCategoriesForUser({ commit }, { userId }) {
      axios.get(`/api/users/${userId}/categories/`).then(response => {
        commit("setCategoriesForUser", response.data);
      });
    }
  }
};
