<template>
  <v-navigation-drawer app v-model="show">
    <v-list expand>
      <v-list-group prepend-icon="mdi-settings" :value="true">
        <template v-slot:activator>
          <v-list-item-title>{{ $t('drawer.settings') }}</v-list-item-title>
        </template>
        <v-list-item>
          <WorkingWeekPicker />
        </v-list-item>
      </v-list-group>
      <v-list-group prepend-icon="mdi-reorder-horizontal" :value="true">
        <template v-slot:activator>
          <v-list-item-title>{{ $t('drawer.categories') }}</v-list-item-title>
        </template>
        <v-list-item>
          <CategoryPicker />
        </v-list-item>
      </v-list-group>
      <v-list-group prepend-icon="mdi-account-multiple" :value="true">
        <template v-slot:activator>
          <v-list-item-title>{{ $t('drawer.teams') }}</v-list-item-title>
        </template>
        <v-list-item>
          <TeamPicker />
        </v-list-item>
      </v-list-group>
      <v-list-group prepend-icon="mdi-account-circle" :value="true">
        <template v-slot:activator>
          <v-list-item-title>{{ $t('drawer.users') }}</v-list-item-title>
        </template>
        <v-list-item>
          <UserPicker />
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import CategoryPicker from "@/components/drawer/CategoryPicker";
import UserPicker from "@/components/drawer/UserPicker";
import TeamPicker from "@/components/drawer/TeamPicker";
import WorkingWeekPicker from "@/components/drawer/WorkingWeekPicker";

export default {
  components: {
    CategoryPicker,
    UserPicker,
    TeamPicker,
    WorkingWeekPicker
  },

  computed: {
    show: {
      get() {
        return this.$store.state.navigation.showDrawer;
      },
      set(value) {
        this.$store.commit("setShowDrawer", value);
      }
    }
  },

  created() {
    window.innerWidth < 600 ? (this.show = false) : (this.show = true);
  }
};
</script>
