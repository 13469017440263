<template>
  <div>
    <v-switch
      ref="switch"
      v-model="selectAll"
      :label="$t('drawer.selectAll')"
      color="primary"
      @change="selectAllChange(teamChange)"
    ></v-switch>
    <v-checkbox
      v-model="selectedData"
      v-for="team in teamsData"
      :key="team.id"
      :label="team.name"
      :value="team.id"
      @change="teamChange"
    ></v-checkbox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { pickerMixin } from "@/mixins/pickerMixin";

export default {
  mixins: [pickerMixin],

  computed: {
    ...mapState({
      teamsData: state => state.teams.teamsData,
      allUserData: state => state.users.allUserData,
      selectedTeams: state => state.calendar.selectedTeams
    }),

    allData() {
      return this.teamsData.map(team => team.id);
    }
  },

  created() {
    this.handleInitialLoad(
      "getTeamsData",
      "selectedTeams",
      this.commitSelectedTeams
    );
  },

  watch: {
    selectedTeams() {
      this.selectedData = this.selectedTeams;
      this.checkIfAllDataIsSelected();
      localStorage.setItem("selectedTeams", JSON.stringify(this.selectedData));
    }
  },

  methods: {
    teamChange() {
      localStorage.setItem("selectedTeams", JSON.stringify(this.selectedData));

      this.selectUsersFromSelectedTeams();
      this.commitSelectedTeams();
    },

    commitSelectedTeams() {
      this.$store.commit("setSelectedTeams", this.selectedData);
    },

    selectUsersFromSelectedTeams() {
      const selectedTeams = this.selectedData;
      const usersIdsFromSelectedTeams = this.allUserData
        .filter(user => selectedTeams.includes(user.team))
        .map(user => user.id);

      this.$store.commit("setSelectedUsers", usersIdsFromSelectedTeams);
    }
  }
};
</script>
